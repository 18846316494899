<template>
  <div class="slots">
    <div class="slots__wrapper container">
      <div class="slots__top">
        <h2 class="slots__title">Nổ hũ</h2>
        <nuxt-link class="slots__more" :to="LOBBY_GAME_URLS.SLOTS_GAME">Xem tất cả</nuxt-link>
      </div>
      <div class="slots__bottom">
        <div class="slots__left">
          <div class="slots__content">
            <GameItem v-for="(item, index) in listGame" :key="index" :item="item" radius="8" show-name />
          </div>
        </div>
        <div class="slots__right">
          <nuxt-link class="slots__item" :to="LOBBY_GAME_URLS.SLOTS_GAME">
            <div class="slots__wrap-img">
              <BaseImg class="image" src="/assets/images/components/desktop/pages/home/slots/banner.webp" />
              <div class="jackpot">
                <client-only>
                  <AnimateCountUp :number="sumJackpot['jackpotNohu']" />
                </client-only>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import GameItem from '~/components/common/game-item.vue'
import { useGame } from '~/composables/game/useGame'
import { LOBBY_GAME_URLS } from '~~/config/page-url'
import AnimateCountUp from '~~/components/common/animate-count-up.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const { fetchSlotsGames, listGame } = useGame()

useAsyncData(async () => {
  await fetchSlotsGames()
})
</script>

<style scoped lang="scss" src="~/assets/scss/components/desktop/pages/home/slots/index.scss"></style>
