<template>
  <component :is="component" />
</template>

<script setup lang="ts">
import useBase from '~/composables/useBase'
import Desktop from '~/components/desktop/pages/home/index.vue'

useBase()

const { $device } = useNuxtApp()
const component = $device.isMobileOrTablet ? 'div' : Desktop

definePageMeta({
  middleware: [
    function (_to, _from) {
      const { $device } = useNuxtApp()
      if (_to.path === '/' && $device.isMobileOrTablet) {
        return navigateTo('/game')
      }
    }
  ]
})
</script>
