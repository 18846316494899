<template>
  <div class="fix-height home__top-banner">
    <div class="hero-banner">
      <Swiper ref="banner" v-bind="swiperOption" class="mySwiper">
        <SwiperSlide v-for="(item, key) in bannerData" :key="key" class="swiper-slide">
          <BannerItem :item="item" :index="key" @click="handleClickBanner(item)" />
        </SwiperSlide>
        <div class="swiper-button">
          <div class="swiper-button-next custom-next" />
          <div class="swiper-button-prev custom-prev" />
        </div>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, EffectCoverflow, Pagination, Navigation, EffectFade } from 'swiper'
import BannerItem from '~/components/desktop/pages/home/banner/banner-item.vue'
import useHeroBanner from '~/composables/home/useHeroBanner'
const { bannerData, fetchBanner, handleClickBanner } = useHeroBanner()
const swiperOption = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: false,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  },
  navigation: {
    nextEl: '.custom-next',
    prevEl: '.custom-prev'
  },
  centeredSlides: true,
  spaceBetween: 0,
  modules: [Autoplay, Navigation, EffectFade]
})

useAsyncData(async () => {
  await fetchBanner()
})
</script>

<style scoped lang="scss" src="~/assets/scss/components/desktop/pages/home/banner/index.scss"></style>
