<template>
  <div class="our-category">
    <div class="our-category__list container">
      <nuxt-link class="item-large" to="/the-thao">
        <div class="item-large__title">thể thao</div>
        <p class="item-large__desc">
          Cá cược bóng đá
          <br />
          Nhanh chóng và an toàn
        </p>
        <BaseImg
          lazy
          class="item-large__image"
          src="/assets/images/components/desktop/pages/home/our-category/sports.webp"
        />
      </nuxt-link>
      <nuxt-link class="item-large" to="/livecasino">
        <div class="item-large__title">Live Casino</div>
        <p class="item-large__desc">
          Chơi thực
          <br />
          Trải nghiệm thực
        </p>
        <BaseImg
          lazy
          class="item-large__image"
          src="/assets/images/components/desktop/pages/home/our-category/casino.webp"
        />
      </nuxt-link>
      <nuxt-link class="item-large" to="/game/no-hu">
        <div class="item-large__title">Nổ hũ/ Slots</div>
        <div class="item-large__jackpot">
          <BaseImg
            lazy
            class="item-large__jackpot--bg"
            src="/assets/images/components/desktop/pages/home/our-category/jackpot-bg.png"
          />
          <BaseImg
            lazy
            class="item-large__jackpot--crown"
            src="/assets/images/components/desktop/pages/home/icon-crown.webp"
          />
          <p class="item-large__jackpot--number">
            <client-only>
              <AnimateCountUp :number="sumJackpot['jackpotNohu']" />
            </client-only>
          </p>
        </div>
        <BaseImg
          lazy
          class="item-large__image"
          src="/assets/images/components/desktop/pages/home/our-category/jackpot.webp"
        />
      </nuxt-link>
      <nuxt-link class="item-large" to="/game/table-game">
        <div class="item-large__title">Table games</div>
        <p class="item-large__desc">
          Chơi thực
          <br />
          Trải nghiệm thực
        </p>
        <BaseImg
          lazy
          class="item-large__image"
          src="/assets/images/components/desktop/pages/home/our-category/cock-fighting.webp"
        />
      </nuxt-link>
      <div class="group-item">
        <nuxt-link class="item-small" to="/game/game-bai">
          <div class="item-small__title">game bài</div>
          <BaseImg
            lazy
            class="item-large__image"
            src="/assets/images/components/desktop/pages/home/our-category/3d-games.webp"
          />
        </nuxt-link>
        <nuxt-link class="item-small" to="/game/ban-ca">
          <div class="item-small__title">bắn cá</div>
          <BaseImg
            lazy
            class="item-large__image"
            src="/assets/images/components/desktop/pages/home/our-category/shooting.webp"
          />

          <p class="item-small__jackpot--number">
            <client-only>
              <AnimateCountUp :number="sumJackpot['jackpotFishing']" />
            </client-only>
          </p>
        </nuxt-link>
      </div>
      <div class="group-item">
        <nuxt-link class="item-small" to="/game/lo-de">
          <div class="item-small__title">lô đề</div>
          <BaseImg
            lazy
            class="item-large__image"
            src="/assets/images/components/desktop/pages/home/our-category/lotto.webp"
          />
        </nuxt-link>
        <nuxt-link class="item-small" to="/game/game-nhanh">
          <div class="item-small__title">Game nhanh</div>
          <BaseImg
            lazy
            class="item-small__image"
            src="/assets/images/components/desktop/pages/home/our-category/fast-game.webp"
          />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { useJackpot } from '~/composables/game/useJackpot'
import AnimateCountUp from '~~/components/common/animate-count-up.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)
const { onSumJackpotInterval, onSumJackpotIntervalClear, onJackpotInterval, onJackpotIntervalClear } = useJackpot()

onMounted(async () => {
  onSumJackpotInterval()
  onJackpotInterval()
})

onBeforeUnmount(() => {
  onSumJackpotIntervalClear()
  onJackpotIntervalClear()
})
</script>

<style scoped lang="scss" src="~/assets/scss/components/desktop/pages/home/our-category/index.scss"></style>
