import { useHotMatchApi } from '~/api/hotmatch'
import { useLoading } from '~/composables/useLoading'
import { RESPONSE_STATUS } from '~/config/constant'
type TTeam = { rate: string; odd: string }
type TTeamStats = { aTeam: TTeam; hTeam: TTeam }
type Team = { name: string; flag_thumbnail: string }

export interface IMatch {
  aTeam: TTeam
  hTeam: TTeam
  ahFT: TTeamStats
  ahHT: TTeamStats
  overFT: TTeamStats
  overHT: TTeamStats
  winFT: TTeamStats
  winHT: TTeamStats
  athena_ahFT?: null | any
  athena_ahHT?: null | any
  athena_league_id: number
  athena_match_id: number
  athena_overFT?: null | any
  athena_overHT?: null | any
  athena_winFT?: null | any
  athena_winHT?: null | any
  awayId: string
  homeId: string
  iLeagueName: string
  ileague_id: string
  imatch_id: string
  league_id: number
  league_image: string
  league_name: string
  league_name_text: string
  match_id: number
  teams: Team[]
  text_time: string
}

export default () => {
  const { isLoading, loading, load } = useLoading()
  const { $axios } = useNuxtApp()
  const { GET_HOT_MATCH } = useHotMatchApi()

  const limit = 10

  const matches = ref<IMatch[]>([])
  loading()
  const fetchHotMatch = async () => {
    try {
      const payload = { params: { limit } }
      const { data: response } = await $axios.get(GET_HOT_MATCH, payload)

      if (response.status === RESPONSE_STATUS.STATUS_OK && response.data?.length) {
        matches.value = response.data
      }
      load()
    } catch (error) {
      load()
      console.log(error)
    }
  }

  return {
    matches,
    isLoading,
    fetchHotMatch
  }
}
