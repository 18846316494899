<template>
  <div class="upcoming-matches">
    <div class="upcoming-matches__wrapper container">
      <div class="upcoming-matches__top">
        <h2 class="upcoming-matches__title">Trận Đấu Hot</h2>
      </div>
      <div class="upcoming-matches__bottom">
        <Swiper v-bind="swiperOptionHotMatch" class="hot-match-swiper">
          <SwiperSlide v-for="match in matches" :key="match.match_id" class="swiper-slide">
            <div class="upcoming-matches__item">
              <div class="matches">
                <h3 class="matches__title">{{ match.league_name_text }}</h3>
                <div class="matches__content">
                  <div class="matches__item left">
                    <p class="matches__img">
                      <BaseImg class="image" :src="match.teams[0].flag_thumbnail" alt="flag" />
                    </p>
                    <p class="matches__team">{{ match.teams[0].name }}</p>
                    <p class="matches__odd">{{ match.hTeam.rate }}</p>
                  </div>
                  <div class="matches__item middle">
                    <p class="matches__wrap-time">
                      <span class="hour">{{ dayjs(match.text_time).format(DATE_FORMATS.SHORT_TIME_FORMAT) }}</span>
                      <span class="date">{{ dayjs(match.text_time).format(DATE_FORMATS.DATE_FORMAT) }}</span>
                    </p>
                    <p class="matches__cta" @click="playSport(match, 'ksports', false)">CƯỢC NGAY</p>
                  </div>
                  <div class="matches__item right">
                    <p class="matches__img">
                      <BaseImg class="image" :src="match.teams[1].flag_thumbnail" alt="flag" />
                    </p>
                    <p class="matches__team right">{{ match.teams[1].name }}</p>
                    <p class="matches__odd">{{ match.aTeam.rate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay } from 'swiper'
import useHotMatch from '~/composables/useHotMatch'
import { useSportNavigation } from '~~/composables/the-thao/useNavigateSport'
import { DATE_FORMATS } from '~/config/constant'
const { matches, fetchHotMatch } = useHotMatch()
const { playSport } = useSportNavigation()
const swiperOptionHotMatch = reactive({
  initialSlide: 0,
  slidesPerView: 3.52,
  slidesPerGroup: 1,
  spaceBetween: 12,
  loop: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  },
  modules: [Autoplay]
})
useAsyncData(async () => {
  await fetchHotMatch()
})
</script>

<style scoped lang="scss" src="~/assets/scss/components/desktop/pages/home/upcoming-matches/index.scss"></style>
