<template>
  <div class="live-casino">
    <div class="live-casino__wrapper container">
      <div class="live-casino__top">
        <h2 class="live-casino__title">Live Casino</h2>
        <nuxt-link class="live-casino__more" to="/livecasino">Xem tất cả</nuxt-link>
      </div>
      <div class="live-casino__bottom">
        <div class="live-casino__left">
          <nuxt-link class="live-casino__item" to="/livecasino/evo">
            <div class="live-casino__wrap-img">
              <BaseImg class="image" lazy src="/assets/images/components/desktop/pages/home/live-casino/banner.webp" />
              <p class="live-casino__overlay">
                <BaseButton class="base-button--bg-golden btn-play" @click="openPageLiveCasino">CHƠI NGAY</BaseButton>
              </p>
            </div>
          </nuxt-link>
        </div>
        <div class="live-casino__right">
          <div class="live-casino__content">
            <GameItem v-for="(item, index) in listCasino" :key="index" :item="item" radius="8" show-name />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import queryString from 'query-string'
import GameItem from '~/components/common/game-item.vue'
import BaseButton from '~/components/common/base-button.vue'
import { useCasino } from '~/composables/casino/useCasino'
import { IGameItem } from '~/types/common'

const { fetchLiveCasino, listCasino } = useCasino()
const listCasinoGames = ref<IGameItem[]>([])
const router = useRouter()

const openPageLiveCasino = () => {
  router.push({ path: '/livecasino/evo' })
}
useAsyncData(async () => {
  await fetchLiveCasino()
})
</script>

<style scoped lang="scss" src="~/assets/scss/components/desktop/pages/home/live-casino/index.scss"></style>
